import { useState, useMemo } from 'react';
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from '../../../utils/hooks/useKeyPress';
import { useSnackbar } from 'notistack';
import { formatApplicationForDownload, createAndDownloadTxtFile } from '../../../utils/filemanagement/exportFunctions';
import { formatDate } from '../../../utils/dateUtils';
import { Application } from '../../../utils/classes/Application';
import { DevHelper } from '../../../utils/DevHelper';

import Chip from '@mui/material/Chip';
import CustomHelmet from "../../common/seo/customhelmet/CustomHelmet";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import Paper from '@mui/material/Paper';
import SectionHeader from "../../common/components/SectionHeader";
import Stack from '@mui/material/Stack';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ViewItem from "./components/ViewItem";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewOptionsMenu from "./viewoptionsmenu";
import ConfirmationDialog from '../../common/dialogs/confirmation';

const View = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const auth = getAuth();
    const { applicationId } = useParams();
    const application = new Application();

    // Dialog 
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [additionalSettingsMenuAnchor, setAdditionalSettingsMenuAnchor] = useState(null);
    const additionalSettingsMenuOpen = Boolean(additionalSettingsMenuAnchor);

    // Firebase
    const QUERY_PATH = `users/${auth.currentUser?.uid}/applications/${applicationId}`
    const db = getDatabase();
    const dbRef = ref(db, QUERY_PATH);
    onValue(dbRef, (snapshot) => {
        if (snapshot.exists) {
            const snapData = snapshot.val();
            application.title = snapData.title;
            application.organisation = snapData.organisation;
            application.location = snapData.location;
            application.locationType = snapData.locationType;
            application.salary = snapData.salary;
            application.applicationDate = snapData.applicationDate;
            application.stage = snapData.stage;
            application.lastUpdate = snapData.lastUpdate;
            application.notes = snapData.notes;
            application.applicationUrl = snapData.applicationUrl;
        }
    });

    /**
     * Delete the reference for the application from Firebase and then return the user to the dashboard.
     */
    const deleteApplicationFromFirebase = () => {
        setDeleteDialogOpen(false)
        set(dbRef, null)
            .then(() => {
                DevHelper.print("info", "Application deleted");
                enqueueSnackbar("Application deleted", { variant: 'success' });
                navigate('/dashboard')
            })
            .error((error) => {
                DevHelper.print("error", "Something went wrong deleting this application", error);
                enqueueSnackbar("Something went wrong", { variant: 'error' });
            });
    }

    const openListing = () => {
        if (application.applicationUrl) {
            window.open(application.applicationUrl, '_blank').focus();
        }
    }

    const editApplication = () => {
        navigate(`/edit/${applicationId}`)
    }

    const downloadApplication = () => {
        enqueueSnackbar("Downloading application", { variant: 'info' })
        createAndDownloadTxtFile((application.organisation ? `${application.title} - ${application.organisation}` : application.title), formatApplicationForDownload(application))
    }

    const shareApplication = () => {
        navigator.share({
            title: application.title,
            text: application.formatForSharing(),
            url: application.applicatinUrl ? application.applicationUrl : window.location.href
        })
            .then(() => DevHelper.print("info", "Successful share"))
            .catch((error) => DevHelper.print("error", "Something went wrong sharing this application", error));
    }

    /**
     * Handle the keyboard shortcut evenets, these are registered in the `useKeyPress` hook.
     */
    const onKeyboardShortcutPressed = (event) => {
        // If a keyboard shortcut is pressed when the menu is open, this should close the menu
        setAdditionalSettingsMenuAnchor(null);

        switch (event.key) {
            case 'o':
                openListing();
                break;
            case 'e':
                editApplication();
                break;
            case 'd':
                setDeleteDialogOpen(true);
                break;
            case 's':
                shareApplication();
                break;
            case 't':
                downloadApplication();
                break;
            case 'Escape':
                navigate('/dashboard');
                break;
            default:
                return;
        }
    }

    useKeyPress(['d', 'e', 'o', 's', 't', 'Escape'], onKeyboardShortcutPressed);

    const menuOptions = useMemo(() => {
        return [
            {
                "title": "View URL",
                "icon": <LinkIcon />,
                "onClick": openListing,
                "shortcut": "o",
                "type": "item"
            },
            {
                "title": "Download",
                "icon": <FileDownloadIcon />,
                "onClick": downloadApplication,
                "shortcut": "t",
                "type": "item"
            },
            {
                "title": "Share",
                "icon": <ShareIcon />,
                "onClick": shareApplication,
                "shortcut": "s",
                "type": "item"
            },
            {
                "type": "divider"
            },
            {
                "title": "Edit",
                "icon": <EditIcon />,
                "onClick": editApplication,
                "shortcut": "e",
                "type": "item"
            },
            {
                "title": "Delete",
                "icon": <DeleteIcon />,
                "onClick": () => setDeleteDialogOpen(true),
                "shortcut": "d",
                "type": "item"
            }
        ]
    }, [application])

    return (
        <div data-testid="ViewPage" className="view-page app-section">
            <CustomHelmet title={application.title ? (application.organisation ? `${application.title} @ ${application.organisation}` : application.title) : "Viewing Application"} noIndex />

            <Stack direction="row" spacing={0.5} justifyContent="flex-end" style={{ marginBottom: '16px' }}>
                <Tooltip title="Application options">
                    <IconButton onClick={(event) => setAdditionalSettingsMenuAnchor(event.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            <SectionHeader title={application.title ? application.title : "Viewing Application"} />

            <ConfirmationDialog
                title="Delete application?"
                message={`Are you sure you want to delete the application ${application.title} with ${application.organisation}? This will be permanent and will not be recoverable.`}
                open={deleteDialogOpen}
                onCancel={() => setDeleteDialogOpen(false)}
                onConfirm={deleteApplicationFromFirebase}
                confirmMessage="Delete" />

            <ViewOptionsMenu
                menuOptions={menuOptions}
                menuOpen={additionalSettingsMenuOpen}
                menuAnchor={additionalSettingsMenuAnchor}
                onClose={() => setAdditionalSettingsMenuAnchor(null)} />

            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                {application.organisation}
            </Typography>

            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" style={{ marginTop: '16px' }}>
                <Chip color={application.getChipColor()} variant="outlined" size="small" label={application.stage} />
                {application.isAged() && <Chip color="error" variant="outlined" size="small" label={`Last update ${application.daysSinceLastUpdate()} days ago`} style={{ marginStart: '8px' }} />}
            </Stack>

            <Paper style={{ padding: '16px', marginTop: '32px', borderRadius: '12px' }} variant="outlined">
                <Grid container spacing={2}>
                    {application.location &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Location" itemContent={application.locationType ? `${application.location} (${application.locationType})` : application.location} />
                        </Grid>
                    }

                    {(!application.location && application.locationType) &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Location" itemContent={application.locationType} />
                        </Grid>
                    }

                    {application.salary &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Salary" itemContent={application.salary} />
                        </Grid>
                    }

                    {application.applicationDate &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Application Date" itemContent={formatDate(application.applicationDate)} />
                        </Grid>
                    }

                    {application.lastUpdate &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Updated" itemContent={formatDate(application.lastUpdate)} />
                        </Grid>
                    }

                    {application.applicationUrl &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Listing" itemContent={application.applicationUrl} wrapContent={false} itemUrl={application.applicationUrl} />
                        </Grid>
                    }
                </Grid>
            </Paper>

            {application.notes &&
                <Paper style={{ padding: '16px', marginTop: '32px', borderRadius: '12px' }} variant="outlined">
                    <Grid item xs={12}>
                        <ViewItem itemTitle="Notes" itemContent={application.notes} markdown showTitle={false} wrapContent />
                    </Grid>
                </Paper>
            }
        </div >
    )
}

export default View;