import CustomHelmet from '../../common/seo/customhelmet/CustomHelmet';
import HomeContent from './components/HomeContent';
import HomeSection from './components/HomeSection';
import SectionFeatures from './components/sections/SectionFeatures';
import SectionIntroduction from './components/sections/SectionIntroduction';
import Introduction from './components/sections/introduction/Introduction';

const Home = () => {

    return (
        <div data-testid="HomePage" className="home-page">
            <CustomHelmet />

            {/* <HomeContent /> */}

            {/* <HomeSection title="introduction" sx={{ height: "900px", width: "100%" }}>
                <SectionIntroduction />
            </HomeSection>

            <HomeSection title="features" sx={{ height: "900px", width: "100%" }}>
                <SectionFeatures />
            </HomeSection> */}

            <Introduction />
        </div>
    )
}

export default Home;