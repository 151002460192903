import { useEffect, useReducer, useState } from "react";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { applicationReducer, INITIAL_STATE, APPLICATION_ACTIONS } from '../../common/reducers/applicationReducer';
import { useSnackbar, closeSnackbar } from 'notistack';
import { DevHelper } from "../../../utils/DevHelper";

import appStages from '../../../data/stages/app_stages.json';
import locationTypes from '../../../data/location_types.json';

import CustomHelmet from "../../common/seo/customhelmet/CustomHelmet";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MarkdownGuideDialog from "../../common/dialogs/markdownguide/MarkdownGuideDialog";
import MarkdownPreviewDialog from '../../common/dialogs/MarkdownPreviewDialog';
import SectionHeader from "../../common/components/SectionHeader";
import TextField from '@mui/material/TextField';
import StandardButton from "../../common/components/buttons/StandardButton/StandardButton";
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

const Edit = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { applicationId } = useParams();
    const db = getDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const application = {
        title: null,
        organisation: null,
        location: null,
        locationType: null,
        salary: null,
        applicationDate: null,
        stage: null,
        lastUpdate: null,
        notes: null,
        applicationUrl: null
    };

    const [formFieldsDisabled, setFormFieldsDisabled] = useState(false);
    const [showMarkdownGuideDialog, setShowMarkdownGuideDialog] = useState(false);
    const [showMarkdownPreviewDialog, setShowMarkdownPreviewDialog] = useState(false);
    const [date] = useState(new Date());

    var dataLoaded = false;

    const [updatedApplication, dispatch] = useReducer(applicationReducer, INITIAL_STATE);

    const path = `users/${auth.currentUser?.uid}/applications/${applicationId}`;

    const dbRef = ref(db, path);
    onValue(dbRef, (snapshot) => {
        if (snapshot.exists) {
            const snapData = snapshot.val();
            application.title = snapData.title ? snapData.title : null;
            application.organisation = snapData.organisation ? snapData.organisation : null;
            application.location = snapData.location ? snapData.location : null;
            application.locationType = snapData.locationType ? snapData.locationType : null;
            application.salary = snapData.salary ? snapData.salary : null;
            application.applicationDate = snapData.applicationDate ? snapData.applicationDate : null;
            application.stage = snapData.stage ? snapData.stage : null;
            application.lastUpdate = snapData.lastUpdate ? snapData.lastUpdate : null;
            application.notes = snapData.notes ? snapData.notes : null;
            application.applicationUrl = snapData.applicationUrl ? snapData.applicationUrl : null;

            dataLoaded = true;
        }
    });

    const updateApplicationInFirebase = () => {
        setFormFieldsDisabled(true);

        if (!application.title || application.title === "") {
            enqueueSnackbar("Please include a title", { variant: 'error' });
            setFormFieldsDisabled(false);
            return;
        }

        const updatingSnackbar = enqueueSnackbar("Updating...", { variant: 'info' });

        try {
            set(dbRef, { ...updatedApplication })
                .then(() => {
                    // Close the 'updating...' snackbar and show a success snackbar
                    closeSnackbar(updatingSnackbar);
                    enqueueSnackbar("Updated", { variant: 'success' });

                    navigate('/dashboard');
                })
                .catch((error) => {
                    enqueueSnackbar("Something went wrong, please try again", { variant: 'error' });
                    console.debug("Error updating application:", error);
                    setFormFieldsDisabled(false)
                });
        } catch (error) {
            enqueueSnackbar("Something went wrong, please try again", { variant: 'error' });
            DevHelper.print("error", "Something went wrong when updating an application", error)
            setFormFieldsDisabled(false);
        }
    }

    useEffect(() => {
        dispatch({ type: APPLICATION_ACTIONS.UPDATE_APPLICATION, payload: { ...application } })
        dispatch({ type: APPLICATION_ACTIONS.UPDATE_LAST_UPDATE, payload: date });
    }, [dataLoaded])

    return (
        <div data-testid="EditPage" className="edit-page app-section">
            <CustomHelmet title={application.title ? application.title : "Editing Application"} noIndex />
            <SectionHeader title={application.title ? application.title : "Editing Application"} />

            <div>
                <Paper style={{ padding: '16px', marginTop: '64px', borderRadius: '12px' }} variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Title"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.title}
                                required
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_TITLE, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Organisation"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.organisation}
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_ORGANISATION, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Salary"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.salary}
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_SALARY, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Application Date"
                                fullWidth
                                autoComplete='off'
                                type="date"
                                defaultValue={application.applicationDate}
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_APPLICATION_DATE, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Location"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.location}
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_LOCATION, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="location-type">Location Type</InputLabel>
                                <NativeSelect
                                    defaultValue={application.locationType}
                                    disabled={formFieldsDisabled}
                                    onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_LOCATION_TYPE, payload: event.target.value })}
                                    inputProps={{
                                        name: 'location-type',
                                        id: 'location-type',
                                    }}
                                    input={<OutlinedInput label="Location Type" />}
                                >
                                    {locationTypes.map(type => {
                                        return (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        )
                                    })}
                                </NativeSelect>
                            </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <TextField
                                label="Updated"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.lastUpdate ? formatDate(application.lastUpdate) : application.lastUpdate}
                                disabled={formFieldsDisabled}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Application URL"
                                fullWidth
                                autoComplete='off'
                                type="url"
                                defaultValue={application.applicationUrl}
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_APPLICATION_URL, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="stage">Stage</InputLabel>
                                <NativeSelect
                                    defaultValue={application.stage}
                                    disabled={formFieldsDisabled}
                                    onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_STAGE, payload: event.target.value })}
                                    inputProps={{
                                        name: 'stage',
                                        id: 'stage',
                                    }}
                                    input={<OutlinedInput label="Stage" />}>
                                    <optgroup label="Active">
                                        {appStages.filter(stage => stage.status === "active").map(stage => {
                                            return (
                                                <option key={stage.id} value={stage.title}>
                                                    {stage.title}
                                                </option>
                                            )
                                        })}
                                    </optgroup>
                                    <optgroup label="Inactive">
                                        {appStages.filter(stage => stage.status === "inactive").map(stage => {
                                            return (
                                                <option key={stage.id} value={stage.title}>
                                                    {stage.title}
                                                </option>
                                            )
                                        })}
                                    </optgroup>
                                </NativeSelect>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Notes"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                multiline
                                minRows={6}
                                defaultValue={application.notes}
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_NOTES, payload: event.target.value })} />

                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{ marginTop: '8px' }}>
                                <StandardButton variant="outlined" content="Markdown Guide" onClick={() => setShowMarkdownGuideDialog(true)} />
                                <StandardButton variant="outlined" content="Preview Note" onClick={() => setShowMarkdownPreviewDialog(true)} disabled={!updatedApplication.notes} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>

                {showMarkdownGuideDialog &&
                    <MarkdownGuideDialog onClose={() => setShowMarkdownGuideDialog(false)} />
                }

                {showMarkdownPreviewDialog &&
                    <MarkdownPreviewDialog onClose={() => setShowMarkdownPreviewDialog(false)} markdown={updatedApplication.notes} />
                }

                <div style={{ marginTop: '8px' }}>
                    <StandardButton content="Update Application" variant="outlined" onClick={updateApplicationInFirebase} disabled={formFieldsDisabled} />
                </div>
            </div>
        </div>
    )
}

export default Edit;