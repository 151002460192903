import HomeSection from "../../../homesection";

import "./Introduction.css";

const Introduction = () => {
    return (
        <HomeSection title="Introduction" centre>
            <h1 data-testid="Introduction--Title" className="introduction-title">
                JobHunter
            </h1>
            <p data-testid="Introduction--Subtitle" className="introduction-subtitle">
                Track your dream job today!
            </p>
        </HomeSection>
    )
}

export default Introduction;