import "./HomeSection.css";

interface HomeSectionProps {
    title: string;
    sx?: any;
    width?: string;
    height?: string;
    backgroundColor?: string;
    centre?: boolean;
    children: React.ReactNode;
}

const HomeSection = (props: HomeSectionProps) => {
    return (
        <section
            data-testid={`Section${props.title.replace(/\s+/g, '')}`}
            className={`home-section home-section-${props.title.toLowerCase().replace(/\s+/g, '-')}`}
            style={{
                ...props.sx,
                padding: '16px',
                boxSizing: "border-box",
                width: props.width ? props.width : "100%",
                height: props.height ? props.height : "auto",
                backgroundColor: props.backgroundColor ? props.backgroundColor : "transparent"
            }}>
            <div className={`home-section-content-container ${props.centre ? "centre" : ""}`}>
                {props.children}
            </div>
        </section>
    )
}

export default HomeSection;