import { DevHelper } from "../DevHelper";
import applicationStages from '../../data/application_stages.json';

export class Application {
    _id: string | undefined;
    _title: string = "";
    _organisation: string | undefined;
    _location: string | undefined;
    _salary: string | undefined;
    _applicationDate: string = "";
    _stage: string = "";
    _lastUpdate: string = "";
    _notes: string | undefined;
    _applicationUrl: string | undefined;

    // Getters and setters
    get status() {
        if (this._stage)
            return applicationStages[this._stage].status;

        return "active";
    }

    set id(id: string | undefined) {
        this._id = id;
    }

    get id(): string | undefined {
        return this._id;
    }

    set title(title: string) {
        this._title = title;
    }

    get title() {
        return this._title;
    }

    set organisation(organisation: string | undefined) {
        this._organisation = organisation;
    }

    get organisation(): string | undefined {
        return this._organisation;
    }

    set location(location: string | undefined) {
        this._location = location;
    }

    get location(): string | undefined {
        return this._location;
    }

    set salary(salary: string | undefined) {
        this._salary = salary;
    }

    get salary(): string | undefined {
        return this._salary;
    }

    set applicationDate(applicationDate: string) {
        this._applicationDate = applicationDate;
    }

    get applicationDate() {
        return this._applicationDate;
    }

    set stage(stage: string) {
        this._stage = stage;
    }

    get stage(): string {
        return this._stage;
    }

    set lastUpdate(lastUpdate: string) {
        this._lastUpdate = lastUpdate;
    }

    get lastUpdate() {
        return this._lastUpdate;
    }

    set notes(notes: string | undefined) {
        this._notes = notes;
    }

    get notes(): string | undefined {
        return this._notes;
    }

    set applicationUrl(applicationUrl: string | undefined) {
        this._applicationUrl = applicationUrl;
    }

    get applicationUrl(): string | undefined {
        return this._applicationUrl;
    }

    // Instance methods

    /**
     * Get the chip color from the JSON file
     * @returns get the chip color from the JSON
     */
    getChipColor(): string {
        if (this._stage)
            return applicationStages[this._stage].color;

        return "success";
    }

    /**
     * Logs the application to the console
     */
    log(): void {
        console.log("Application: " + this._title + " " + this._organisation + " " + this._location + " " + this._salary + " " + this._applicationDate + " " + this._stage + " " + this._lastUpdate + " " + this._notes + " " + this._applicationUrl);
    }

    /**
     * Determines if the application is aged, set by the `DAYS_TILL_AGED` constant
     * @returns true if the application is aged, false otherwise
     */
    isAged(): boolean {
        const DAYS_TILL_AGED = 30;

        try {
            return this.daysSinceLastUpdate() >= DAYS_TILL_AGED;
        } catch (error) {
            DevHelper.print("error", "Issue calculating if application is aged", error);
        }

        return false;
    }

    daysSinceLastUpdate(): number {
        try {
            const lastUpdateDate = new Date(this._lastUpdate);
            const currentDatetime = new Date();

            const differenceBetweenDates = currentDatetime.getTime() - lastUpdateDate.getTime();
            const daysBetweenDates = Math.ceil(differenceBetweenDates / (1000 * 3600 * 24));

            return daysBetweenDates;
        } catch (error) {
            DevHelper.print("error", "Issue calculating if application is aged", error);
        }

        return 0;
    }

    formatForSharing(): string {
        let formattedString = "";

        formattedString += "Title: " + this._title + "\n";

        if (this._organisation) {
            formattedString += "Organisation: " + this._organisation + "\n";
        }

        if (this._location) {
            formattedString += "Location: " + this._location + "\n";
        }

        if (this._salary) {
            formattedString += "Salary: " + this._salary + "\n";
        }

        if (this._applicationUrl) {
            formattedString += this._applicationUrl + "\n";
        }

        return formattedString;
    }

    // Static methods

    /**
     * Determines if the application has changes made by the user
     * @param original application to compare
     * @param edited application to compare
     * @returns true if the applications have changes, false otherwise
     */
    static applicationHasChanges(original: Application, edited: Application): boolean {
        if (original._title !== edited._title) return true;

        if (original._organisation !== edited._organisation) return true;

        if (original._location !== edited._location) return true;

        if (original._salary !== edited._salary) return true;

        if (original._applicationDate !== edited._applicationDate) return true;

        if (original._stage !== edited._stage) return true;

        if (original._notes !== edited._notes) return true;

        if (original._applicationUrl !== edited._applicationUrl) return true;

        return false;
    }

    static hasValidTitle(title: string) {
        return title !== null && title.length > 0;
    }
}