import { forwardRef } from 'react';
import { DeviceHelper } from '../../../../utils/DeviceHelper';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MarkdownGuideStylingText from './components/MarkdownGuideStylingText';
import MarkdownGuideHeadings from './components/MarkdownGuideHeadings';
import MarkdownGuideQuotes from './components/MarkdownGuideQuotes';
import MarkdownGuideMisc from './components/MarkdownGuideMisc';
import MarkdownGuideLists from './components/MarkdownGuideLists';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MarkdownGuideDialog = ({ onClose }) => {
    return (
        <Dialog
            data-testid="MarkdownGuideDialog"
            className="markdown-guide-dialog"
            open
            fullScreen
            onClose={onClose}
            TransitionComponent={Transition}
            scroll="paper">
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Markdown Guide
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className="markdown-guide-dialog-content">
                <MarkdownGuideStylingText isMobile={DeviceHelper.isMobileDevice()} />
                <MarkdownGuideHeadings isMobile={DeviceHelper.isMobileDevice()} />
                <MarkdownGuideLists isMobile={DeviceHelper.isMobileDevice()} />
                <MarkdownGuideQuotes isMobile={DeviceHelper.isMobileDevice()} />
                <MarkdownGuideMisc isMobile={DeviceHelper.isMobileDevice()} />
            </DialogContent>
        </Dialog>
    )
}

export default MarkdownGuideDialog;