import { useEffect, useReducer, useState, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, set, push } from 'firebase/database';
import { applicationReducer, INITIAL_STATE, APPLICATION_ACTIONS } from '../../common/reducers/applicationReducer';
import { useSnackbar, closeSnackbar } from 'notistack';
import { formatDate } from '../../../utils/dateUtils';
import { DevHelper } from '../../../utils/DevHelper';

import appStages from '../../../data/stages/app_stages.json';
import locationTypes from '../../../data/location_types.json';

import CustomHelmet from "../../common/seo/customhelmet/CustomHelmet";
import ConfirmationDialog from '../../common/dialogs/confirmation';
import Grid from '@mui/material/Grid';
import SectionHeader from "../../common/components/SectionHeader";
import MarkdownPreviewDialog from '../../common/dialogs/MarkdownPreviewDialog';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import StandardButton from '../../common/components/buttons/StandardButton/StandardButton';
const MarkdownGuideDialog = lazy(() => import('../../common/dialogs/markdownguide/MarkdownGuideDialog'));

const Add = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [formFieldsDisabled, setFormFieldsDisabled] = useState(false);
    const [showMarkdownGuideDialog, setShowMarkdownGuideDialog] = useState(false);
    const [showMarkdownPreviewDialog, setShowMarkdownPreviewDialog] = useState(false);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const [date] = useState(new Date());

    const [application, dispatch] = useReducer(applicationReducer, INITIAL_STATE);

    /**
     * Save the application to Firebase, this will perform validation on the data before saving
     * @param {Boolean} addMore - Whether to add another application or not
     */
    const saveApplicationToFirebase = (addMore = false) => {
        setFormFieldsDisabled(true);

        DevHelper.printTable(application);

        if (!application.title) {
            enqueueSnackbar("Please include a title", { variant: 'error' });
            setFormFieldsDisabled(false);
            return;
        }

        const savingSnackbar = enqueueSnackbar("Saving...", { variant: 'info' });

        const database = getDatabase();
        const applicationListRef = ref(database, `/users/${getAuth().currentUser?.uid}/applications`);
        const newApplicationRef = push(applicationListRef);

        // Add the data to the database
        set(newApplicationRef, { ...application })
            .then(() => {
                // Close the 'saving...' snackbar and show a success message, this should clean things up if things go faster than expected
                closeSnackbar(savingSnackbar);
                enqueueSnackbar("Saved", { variant: 'success' });

                if (addMore) {
                    document.getElementById('add-application-form').reset();
                    dispatch({ type: APPLICATION_ACTIONS.CLEAR });
                    setFormFieldsDisabled(false);
                } else {
                    navigate('/dashboard');
                }
            })
            .catch((error) => {
                enqueueSnackbar("Something went wrong. Please try again.", { variant: 'error' });
                DevHelper.print("error", "Something went wrong when saving", error);
                setFormFieldsDisabled(false);
            })
    }

    /**
     * Update the last update date when the page loads to the current date
     */
    useEffect(() => {
        document.getElementById("add-application-form-application-date").defaultValue = formatDate(date);
        dispatch({ type: APPLICATION_ACTIONS.UPDATE_LAST_UPDATE, payload: date });
        dispatch({ type: APPLICATION_ACTIONS.UPDATE_APPLICATION_DATE, payload: formatDate(date) });
    }, []);

    return (
        <div data-testid="AddPage" className="add-page app-section">
            <CustomHelmet title="Add Application" noIndex />
            <SectionHeader title="Add Application" />

            <ConfirmationDialog
                open={showUnsavedChangesDialog}
                title="Unsaved Changes"
                message="You currently have unsaved changes that will be lost if you leave this page. Would you like to continue?"
                onCancel={() => setShowUnsavedChangesDialog(false)}
                onConfirm={() => {
                    setShowUnsavedChangesDialog(false);
                    saveApplicationToFirebase(false);
                }}
                cancelMessage="Delete"
                confirmMessage="Save" />

            {/** Add application form */}
            <form id="add-application-form">
                <Paper style={{ padding: '16px', marginTop: '64px', borderRadius: '12px' }} variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="add-application-form-title"
                                label="Title"
                                fullWidth
                                autoComplete='on'
                                type="text"
                                required
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_TITLE, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Organisation"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_ORGANISATION, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Salary"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_SALARY, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="add-application-form-application-date"
                                label="Application Date"
                                fullWidth
                                autoComplete='off'
                                type="date"
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_APPLICATION_DATE, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Location"
                                fullWidth
                                autoComplete='on'
                                type="text"
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_LOCATION, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="location-type">Location Type</InputLabel>
                                <NativeSelect
                                    disabled={formFieldsDisabled}
                                    onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_LOCATION_TYPE, payload: event.target.value })}
                                    inputProps={{
                                        name: 'location-type',
                                        id: 'location-type',
                                    }}
                                    input={<OutlinedInput label="Location Type" />}
                                >
                                    {locationTypes.map(type => {
                                        return (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        )
                                    })}
                                </NativeSelect>
                            </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <TextField
                                id="add-application-form-last-update"
                                // label="Updated"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                value={formatDate(application.lastUpdate)}
                                disabled={formFieldsDisabled}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="add-application-form-application-url"
                                label="Application URL"
                                fullWidth
                                autoComplete='off'
                                type="url"
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_APPLICATION_URL, payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="stage">Stage</InputLabel>
                                <NativeSelect
                                    disabled={formFieldsDisabled}
                                    onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_STAGE, payload: event.target.value })}
                                    inputProps={{
                                        name: 'stage',
                                        id: 'stage',
                                    }}
                                    defaultValue={appStages[1].title}
                                    input={<OutlinedInput label="Stage" />}>
                                    <optgroup label="Active">
                                        {appStages.filter(stage => stage.status === "active").map(stage => {
                                            return (
                                                <option key={stage.id} value={stage.title}>
                                                    {stage.title}
                                                </option>
                                            )
                                        })}
                                    </optgroup>
                                    <optgroup label="Inactive">
                                        {appStages.filter(stage => stage.status === "inactive").map(stage => {
                                            return (
                                                <option key={stage.id} value={stage.title}>
                                                    {stage.title}
                                                </option>
                                            )
                                        })}
                                    </optgroup>
                                </NativeSelect>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="add-application-form-notes"
                                label="Notes"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                multiline
                                minRows={6}
                                style={{ whiteSpace: 'pre-wrap' }}
                                disabled={formFieldsDisabled}
                                onChange={event => dispatch({ type: APPLICATION_ACTIONS.UPDATE_NOTES, payload: event.target.value })} />

                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{ marginTop: '8px' }}>
                                <StandardButton variant="outlined" content="Markdown Guide" onClick={() => setShowMarkdownGuideDialog(true)} />
                                <StandardButton variant="outlined" content="Preview Note" onClick={() => setShowMarkdownPreviewDialog(true)} disabled={!application.notes} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>

                {showMarkdownGuideDialog &&
                    <MarkdownGuideDialog onClose={() => setShowMarkdownGuideDialog(false)} />
                }

                {showMarkdownPreviewDialog &&
                    <MarkdownPreviewDialog onClose={() => setShowMarkdownPreviewDialog(false)} markdown={application.notes} />
                }

                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    style={{ marginTop: '32px' }}>
                    <StandardButton content="Save Application" onClick={() => saveApplicationToFirebase()} disabled={formFieldsDisabled} variant="outlined" />
                    <StandardButton content="Save & Add Another" onClick={() => saveApplicationToFirebase(true)} disabled={formFieldsDisabled} variant="outlined" />
                </Stack>
            </form>
        </div >
    )
}

export default Add;