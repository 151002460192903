import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CustomHelmet from "../../../common/seo/customhelmet/CustomHelmet";
import SectionHeader from "../../../common/components/SectionHeader";
import Typography from '@mui/material/Typography';

const NotAuthenticated = () => {
    const navigate = useNavigate();

    return (
        <div data-testid="NotAuthenticatedPage" className="not-authenticated-page app-section">
            <CustomHelmet title="Not Authenticated" noIndex />
            <SectionHeader title="You need to login to view this content" />

            <Typography variant="body1" textAlign="center">
                You're trying to view content that is sensitive, or account related.
            </Typography>

            <div style={{ textAlign: 'center', marginTop: '64px' }}>
                <Button variant="contained" onClick={() => navigate("/login")}>
                    Login
                </Button>
            </div>
        </div>
    )
}

export default NotAuthenticated;