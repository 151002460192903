import { useState, useEffect, Suspense } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useOnlineStatus } from '../../utils/hooks/useOnlineStatus';
import { confirmAuthenticatedToView } from '../../utils/routes/protectedRoutes';
import { DevHelper } from '../../utils/DevHelper';
import { getAuth } from 'firebase/auth';

import Loading from './states/loading';
import Header from '../common/header';
import Offline from './offline';
import NotAuthenticated from './states/notauthenticated';

const Layout = ({ userState }) => {
    const isOnline = useOnlineStatus();
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const [isAuthenticatedToView, setIsAuthenticatedToView] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        setIsAuthenticatedToView(confirmAuthenticatedToView(location, userState));

        if (!isAuthenticatedToView) {
            DevHelper.print("info", "User is not authenticated to view this page");
        };

        if (auth?.currentUser && location === "/") {
            navigate("/dashboard");
        }
    }, [location, userState]);

    return (
        <div data-testid="Layout" >
            <Header authenticationLoading={userState.loading} />

            {(isOnline && !userState.loading) &&
                <Suspense fallback={<Loading />}>
                    {isAuthenticatedToView
                        ? <Outlet />
                        : <NotAuthenticated />
                    }
                </Suspense>
            }

            {(isOnline && userState.loading) &&
                <Loading />
            }

            {!isOnline &&
                <Offline />
            }
        </div>
    )
}

export default Layout;