import './App.css';

import { useEffect, useState, useRef } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { firebaseConfig } from './config/FirebaseConfig';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTheme } from './config/Theme';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import Routing from './utils/Routing';

function App() {
  // Initialise Firebase and get current signed in
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  // eslint-disable-next-line
  const database = getDatabase(app);
  let mounted = useRef(false);

  const [userState, setUserState] = useState({
    loading: true,
    authenticated: false
  });

  useEffect(() => {
    mounted.current = true;
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserState({
          loading: false,
          authenticated: true
        })
      } else {
        setUserState({
          loading: false,
          authenticated: false
        })
      }
    });

    return () => {
      mounted.current = false;
      unsubscribe();
    }
  }, [auth])

  return (
    <Router>
      <ThemeProvider theme={useTheme()}>
        <SnackbarProvider maxSnack={3} preventDuplicate>
          <div data-testid="app" className="App">
            <Routing userState={userState} />
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
