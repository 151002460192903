export const INITIAL_STATE = {
    title: null,
    organisation: null,
    salary: null,
    location: null,
    locationType: null,
    applicationDate: null,
    lastUpdate: null,
    applicationUrl: null,
    stage: "Applied",
    notes: null
}

export const APPLICATION_ACTIONS = {
    UPDATE_TITLE: "UPDATE_TITLE",
    UPDATE_ORGANISATION: "UPDATE_ORGANISATION",
    UPDATE_SALARY: "UPDATE_SALARY",
    UPDATE_LOCATION: "UPDATE_LOCATION",
    UPDATE_LOCATION_TYPE: "UPDATE_LOCATION_TYPE",
    UPDATE_APPLICATION_DATE: "UPDATE_APPLICATION_DATE",
    UPDATE_LAST_UPDATE: "UPDATE_LAST_UPDATE",
    UPDATE_APPLICATION_URL: "UPDATE_APPLICATION_URL",
    UPDATE_STAGE: "UPDATE_STAGE",
    UPDATE_NOTES: "UPDATE_NOTES",
    UPDATE_APPLICATION: "UPDATE_APPLICATION",
    CLEAR: "CLEAR"
}

export const applicationReducer = (state, action) => {
    switch (action.type) {
        case APPLICATION_ACTIONS.UPDATE_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_ORGANISATION:
            return {
                ...state,
                organisation: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_SALARY:
            return {
                ...state,
                salary: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_LOCATION:
            return {
                ...state,
                location: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_LOCATION_TYPE:
            return {
                ...state,
                locationType: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_APPLICATION_DATE:
            return {
                ...state,
                applicationDate: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_LAST_UPDATE:
            return {
                ...state,
                lastUpdate: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_APPLICATION_URL:
            return {
                ...state,
                applicationUrl: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_STAGE:
            return {
                stage: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_NOTES:
            return {
                ...state,
                notes: action.payload
            }
        case APPLICATION_ACTIONS.UPDATE_APPLICATION:
            return action.payload;
        case APPLICATION_ACTIONS.CLEAR:
            return INITIAL_STATE;
        default:
            return state;
    }
}