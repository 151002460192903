import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorBoundary from '../../errorboundary/ErrorBoundary';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmMessage?: string;
    cancelMessage?: string;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    return (
        <ErrorBoundary hideError>
            <Dialog
                data-testid="ConfirmationDialogComponent"
                open={props.open}
                keepMounted
                onClose={props.onCancel}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-message">
                <DialogTitle id="confirmation-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-message">
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onCancel}>
                        {props.cancelMessage ? props.cancelMessage : "Cancel"}
                    </Button>
                    <Button onClick={props.onConfirm} autoFocus>
                        {props.confirmMessage ? props.confirmMessage : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        </ErrorBoundary>
    )
}

export default ConfirmationDialog;