/**
 * Get a list of locales that the user has preferences for in the brower, this will return an array of their locales
 * @returns an array of the locales or undefined
 */
export const getLocales = () => {
    // This falls back to `navigator.language` if `navigator.languages` is undefined - `navigator.language` is the UI language
    const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

    if (!browserLocales) return undefined;

    return browserLocales;
}

/**
 * Get the first preferred language for the user, if this is not defined, this will make use of the UI language of the browser.
 * @returns a string locale
 */
export const getUserPreferredLocale = () => {
    const userLocales = getLocales();

    if (userLocales !== undefined) return userLocales[0];
}