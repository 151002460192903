import { useSyncExternalStore } from "react"

const subscribe = (callback) => {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
        window.removeEventListener("online", callback);
        window.removeEventListener("offline", callback);
    };
}

/**
 * Check if the user has network access.
 * @returns current online state
 */
export const useOnlineStatus = () => {
    return useSyncExternalStore(
        subscribe,
        () => navigator.onLine, // client value
        () => true // server value
    )
}