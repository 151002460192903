import { createTheme } from "@mui/material/styles";
import { useMemo } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

const useTheme = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    return useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light'
                },
                components: {
                    MuiAppBar: {
                        styleOverrides: {
                            colorPrimary: {
                                backgroundColor: prefersDarkMode ? '#1f1f1f' : '#f1f1f1', // #f1f1f1 - light
                                color: prefersDarkMode ? '#f1f1f1' : '#1f1f1f', // #1f1f1f - light
                            }
                        }
                    }
                }
            }),
        [prefersDarkMode],
    )
}

export { useTheme }