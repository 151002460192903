export const validatePassword = (password, confirmPassword) => {
    if (password.length < 8) {
        return "Password must be longer than 8 characters";
    }

    if (confirmPassword) {
        if (password !== confirmPassword) return "Passwords must match";
    }

    var regPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (!regPattern.test(password)) {
        return "Password should contain atleast one number and one special character";
    }

    return null;
}

/**
 * Validate email address is valid.
 * @param {String} email - to validate
 * @returns true if valid
 */
export const validateEmail = (email) => {
    var regPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i
    return regPattern.test(email);
}

/**
 * Hyphenate a string and change it to lower case, this can be used for creating slugs or in classnames
 * @param {String} string - string to hyphenate
 * @returns hyphenated string
 */
export const hyphenateString = (string) => {
    return string.replace(/\s+/g, '-').toLowerCase();
}