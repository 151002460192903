import { getUserPreferredLocale } from "./localeUtils";

/**
 * Format the date into a human readable string. This formats it based on locale of the user. This will only do day, month, year
 * @param {EpochTimeStamp} ogDate epoch for date to format
 * @returns a string date.
 */
export const formatDate = (ogDate) => {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    try {
        const date = new Date(ogDate);

        let options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        }

        return new Intl.DateTimeFormat(getUserPreferredLocale(), options).format(date);
    } catch (error) {
        return ogDate;
    }
}

/**
 * Compare the date to now and return the number of days between the two
 * @param {EpochTime} date - epoch time to compare to now
 * @returns the number of days between the date and now
 */
export const daysBetween = (date) => {
    try {
        const then = new Date(date);
        const now = new Date();

        const diff = now - then;
        return Math.ceil(diff / (1000 * 60 * 60 * 24));
    } catch (error) {
        return 0;
    }
}