export class DeviceHelper {
    static isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    static isAppleDevice() {
        return /iPhone|iPad|iPod|Mac|Macintosh/i.test(navigator.userAgent);
    }

    static isAndroidDevice() {
        return /Android/i.test(navigator.userAgent);
    }

    static isDesktopDevice() {
        return !this.isMobileDevice();
    }

    static isWindowsDevice() {
        return /Windows/i.test(navigator.userAgent);
    }

    static userPrefersDarkMode() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
}