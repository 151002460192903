import { DeviceHelper } from '../../../../utils/DeviceHelper';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ErrorBoundary from '../../../common/errorboundary/ErrorBoundary';
import Typography from '@mui/material/Typography';

interface ViewOptionsMenuProps {
    menuOptions: {
        title: string;
        icon: any;
        shortcut?: string;
        onClick: () => void;
        type: "item" | "divider";
        disabled?: boolean;
    }[];
    menuOpen: boolean;
    menuAnchor: any;
    onClose: () => void;
}

const ViewOptionsMenu = (props: ViewOptionsMenuProps) => {
    const handleMenuItemClick = (menuItemOnClick: () => void) => {
        props.onClose();
        menuItemOnClick();
    }

    return (
        <ErrorBoundary hideError>
            <Menu
                data-testid="ViewOptionsMenuComponent"
                anchorEl={props.menuAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                open={props.menuOpen}
                onClose={props.onClose}>
                {props.menuOptions?.map((option, index) => {
                    return option.type === "item"
                        ? (
                            <MenuItem key={index} onClick={(e) => handleMenuItemClick(option.onClick)} disabled={option.disabled}>
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText>
                                    {option.title}
                                </ListItemText>
                                {DeviceHelper.isDesktopDevice() &&
                                    <Typography variant="body2" color="text.secondary" style={{ paddingLeft: '16px' }}>
                                        {option.shortcut}
                                    </Typography>
                                }
                            </MenuItem>
                        )
                        : <Divider key={index} />
                })}
            </Menu>
        </ErrorBoundary>
    )
}

export default ViewOptionsMenu;