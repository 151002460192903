import Typography from '@mui/material/Typography';

const SectionHeader = ({ title, hidden = false }) => {
    return (
        <div style={{ display: hidden ? 'none' : 'block' }}>
            <Typography variant="h1" style={{ fontSize: '1.5rem', textTransform: 'uppercase', textAlign: 'center', marginBottom: '32px' }}>
                {title}
            </Typography>
        </div>
    )
}

export default SectionHeader;