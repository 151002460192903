interface ErrorStateProps {
    hideError?: boolean;
}

const ErrorState = (props: ErrorStateProps) => {
    if (props.hideError) return null;

    return (
        <div data-testid="ErrorStateComponent" className="error-state" style={{ textAlign: 'center' }}>
            <p>Something went wrong...</p>
        </div>
    )
}

export default ErrorState;