import { forwardRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ReactMarkdown from 'react-markdown';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MarkdownPreviewDialog = ({ onClose, markdown }) => {
    return (
        <Dialog
            className="markdown-preview-dialog"
            open
            fullWidth
            onClose={onClose}
            TransitionComponent={Transition}
            scroll="paper"
            aria-labelledby="markdown-preview-dialog-title">
            <DialogTitle id="markdown-preview-dialog-title">Preview</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        borderColor: 'gray',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '6px',
                        padding: '16px',
                        wordWrap: 'break-word'
                    }}>
                    <ReactMarkdown>
                        {markdown}
                    </ReactMarkdown>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Close Preview
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MarkdownPreviewDialog;