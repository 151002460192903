import Button from '@mui/material/Button';

import './StandardButton.css';

interface StandardButtonProps {
    content: string;
    onClick: () => void;
    disabled?: boolean;
    classes?: string;
    type?: "button" | "submit" | "reset" | undefined;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    variant?: "text" | "outlined" | "contained" | undefined;
    sx?: any[];
}

const StandardButton = (props: StandardButtonProps) => {
    return (
        <Button
            data-testid="StandardButtonComponent"
            className={`standard-button ${props.classes}`}
            type={props.type}
            onClick={props.onClick}
            disabled={props.disabled}
            startIcon={props.startIcon}
            endIcon={props.endIcon}
            variant={props.variant}>
            {props.content}
        </Button>
    )
}

export default StandardButton;