import markdownGuide from '../../../../../data/markdown/markdown_guide_misc.json';
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';

const MarkdownGuideMisc = ({ isMobile }) => {
    return (
        <div className="markdown-guide-misc markdown-guide">
            <Typography variant="h4">{markdownGuide.title}</Typography>
            <ReactMarkdown>
                {markdownGuide.description}
            </ReactMarkdown>
            <table className="markdown-guide-table">
                <tr>
                    <th>Style</th>
                    <th>Syntax</th>
                    <th>Example</th>
                    {!isMobile && <th>Output</th>}
                </tr>
                {markdownGuide.guides.map((guide, index) => {
                    return (
                        <tr key={index}>
                            <td>{guide.style}</td>
                            <td>{guide.syntax}</td>
                            <td>{guide.example}</td>
                            {!isMobile && <td><ReactMarkdown>{guide.example}</ReactMarkdown></td>}
                        </tr>
                    )
                })}
            </table>
            <Divider />
        </div>
    )
}

export default MarkdownGuideMisc;