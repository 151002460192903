import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import config from '../config/config.json';

import Add from '../components/pages/add/Add';
import Edit from '../components/pages/edit/Edit';
import Home from '../components/pages/home';
import Layout from '../components/pages/Layout';
import View from '../components/pages/view/View';
const Analytics = lazy(() => import('../components/pages/analytics'));
const Dashboard = lazy(() => import('../components/pages/dashboard/Dashboard'));
const Export = lazy(() => import('../components/pages/export/Export'));
const Login = lazy(() => import('../components/pages/login'));
const Resources = lazy(() => import('../components/pages/resources/Resources'));
const Register = lazy(() => import('../components/pages/register/Register'));
const Search = lazy(() => import('../components/pages/search'));
const Settings = lazy(() => import('../components/pages/settings/Settings'));
const Shortcuts = lazy(() => import('../components/pages/shortcuts'));
const Privacy = lazy(() => import('../components/pages/privacy'));
const Help = lazy(() => import('../components/pages/help'));
const Pricing = lazy(() => import('../components/pages/pricing'));

const Routing = ({ userState }) => {
    return (
        <Routes>
            <Route path="/" element={<Layout userState={userState} />}>
                <Route path="/" element={<Home />} />
                {config.features.settings.enabled && <Route path="/settings" element={<Settings />} />}
                {config.features.analytics.enabled && <Route path="/analytics" element={<Analytics />} />}
                {config.features.add.enabled && <Route path="/add" element={<Add />} />}
                <Route path="/dashboard" element={<Dashboard />} />
                {config.features.edit.enabled && <Route path="/edit" element={<Edit />} />}
                {config.features.edit.enabled && <Route path="/edit/:applicationId" element={<Edit />} />}
                {config.features.export.enabled && <Route path="/account/export" element={<Export />} />}
                {config.features.login.enabled && <Route path="/login" element={<Login />} />}
                {config.features.resources.enabled && <Route path="/resources" element={<Resources />} />}
                {config.features.register.enabled && <Route path="/register" element={<Register />} />}
                {config.features.search.enabled && <Route path="/search" element={<Search />} />}
                {config.features.shortcuts.enabled && <Route path="/shortcuts" element={<Shortcuts />} />}
                {config.features.view.enabled && <Route path="/view" element={<View />} />}
                {config.features.view.enabled && <Route path="/view/:applicationId" element={<View />} />}
                {config.features.privacy.enabled && <Route path="/privacy" element={<Privacy />} />}
                {config.features.pricing.enabled && <Route path="/pricing" element={<Pricing />} />}
                {config.features.help.enabled && <Route path="/help" element={<Help />} />}
            </Route>
        </Routes>
    );
};

export default Routing;
